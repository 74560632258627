import { makeStyles, Theme } from '@material-ui/core/styles';
import cn from 'classnames';
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { useInterval } from 'react-use';
import {
    BroadcastPersistentState,
    RequestType,
    SessionRenderType,
    SessionRequestProcessingResult,
} from 'wavepaths-shared/core';
import { millisecondsToMinutes } from 'wavepaths-shared/util/dateUtils';

import { AnimatedLoaderIcon, Dialog, Warning } from '@/component-library';
import { GenericErrorBoundary } from '@/components/GenericErrorBoundary';
import UpgradeBanner from '@/components/InSession/UpgradeBanner';
import { setScrollbarTheme } from '@/components/StyledSrollbar';
import { Features } from '@/features';

import { AuthContext, useAuthContext } from '../../auth';
import * as sessionApi from '../../common/api/sessionApi';
import LoadingOrb from '../../common/components/LoadingOrb';
import Snackbar, { GlobalSnackbarContext } from '../../common/components/Snackbar';
import { isSessionCancelled } from '../../common/domain/session';
import { useMonitoringForSessionInit } from '../../common/hooks/useMonitoringForSessionInit';
import { useRemoteSessionState, useSessionRemoteTick } from '../../common/hooks/useSessionTick';
import { useSnackbar } from '../../common/hooks/useSnackbar';
import { FreudConnection } from '../../freudConnection/FreudConnection';
import UserEvents from '../../UserEvents';
import QueuedItem from './actionQueue/QueuedItem';
import { useActionQueue } from './actionQueue/useActionQueue';
import { AutoGuide } from './autoGuide/AutoGuide';
import useRemoteSession from './autoGuide/useSession';
import { useRemoteSessionScorePlanner } from './autoGuide/useSessionScore';
import { PlayerChoiceContextWrapper } from './deviceOptions/PlayerChoiceContext';
import { GuideEndOfSessionFeedbackContainer } from './GuideEndOfSessionFeedback';
import { GuideHeader } from './GuideHeader';
// import useAudioState from './useAudioState';
import useFreudConnection from './useFreudConnection';
import useIsNetworkSlow from './useIsNetworkSlow';
import { OnlineContext } from './useIsOnline';
// TODO: the two useState hooks could maybe be one big hook or these could be named more specifically
import { useRemoteSessionLogs } from './useSessionState';

const useStyles = makeStyles<Theme>({
    snackbar: {
        position: 'absolute',
    },
    warning: {
        position: 'absolute',
    },
    guide: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F7F5F2',
    },
    main: {
        position: 'relative',
        height: '100%',
    },
});

export interface GuideParams {
    sessionId: string;
}

export const USER_OFFLINE_LABEL = 'You are offline. Please reconnect.';
export const NETWORK_SLOW_LABEL = 'Your internet connection appears to be unstable or too slow.';

export const useRemoteSessionControls = ({ sessionId, mode }: { sessionId?: string; mode?: 'control' | 'listen' }) => {
    const { isOnline } = useContext(OnlineContext);
    const authCtx = useContext(AuthContext);

    const [connection, setConnection] = useState<FreudConnection | undefined>(undefined);
    const { session, error: sessionError, startSession } = useRemoteSession(sessionId, connection);
    const freudConnection = useFreudConnection({ session, firebaseUser: authCtx.firebaseUser, mode });

    // hacky work around for circular dependency between useSession and useFreudConnection
    useEffect(() => {
        if (freudConnection) {
            setConnection(freudConnection);
        }
    }, [freudConnection]);

    // const audioState = useAudioState({ connection });

    const tick = useSessionRemoteTick();
    const [eventTrackingDone, setEventTrackingDone] = useState(false);
    const [showConfirmEndSession, setShowConfirmEndSession] = useState(false);
    const { content: snackbarContent, setSnackbarContent, closeSnackbar } = useSnackbar();
    const [warningContent, setWarningContent] = useState<string | undefined>(undefined);

    const isNetworkSlow = useIsNetworkSlow(connection);

    useEffect(() => {
        if (isOnline === false) {
            setWarningContent(USER_OFFLINE_LABEL);
        } else if (warningContent === USER_OFFLINE_LABEL) {
            setWarningContent(undefined);
        }
    }, [isOnline, setWarningContent, warningContent]);

    useEffect(() => {
        if (isNetworkSlow) {
            setWarningContent(NETWORK_SLOW_LABEL);
        } else if (warningContent === NETWORK_SLOW_LABEL) {
            setWarningContent(undefined);
        }
    }, [isNetworkSlow, setWarningContent]);

    const remoteSessionLogs = useRemoteSessionLogs({ connection });
    const classes = useStyles();

    const {
        queuedFunction,
        onCancel: onCancelQueuedFunction,
        onSkipQueue,
        queueFunction,
        onSkipQueueAndDontAskMore,
    } = useActionQueue();
    const sessionScoreState = useRemoteSessionScorePlanner(sessionId, connection, queueFunction);

    const timeElapsed = tick ? tick.absoluteTime : null;

    const sessionDuration = tick?.sessionDuration;

    //@ts-ignore
    const {
        inPostlude: sessionInPostlude,
        ended: sessionEnded,
        initialised: sessionInitialised,
        started: sessionStarted,
    } = useRemoteSessionState();

    useEffect(() => {
        if (sessionError) {
            setSnackbarContent('Unknown session error');
        }
    }, [sessionError]);

    const timeElapsedInMinutes = timeElapsed ? Math.round(millisecondsToMinutes(timeElapsed)) : null;
    const sessionDurationInMinutes = sessionDuration ? Math.round(millisecondsToMinutes(sessionDuration)) : undefined;
    const trackEndedSession = useCallback(
        ({
            overwriteAsCompleted,
            sessionCompletness,
        }: {
            overwriteAsCompleted?: boolean;
            sessionCompletness?: number;
        }) => {
            if (eventTrackingDone || !session) return;
            setEventTrackingDone(true);
            if (isSessionCancelled(timeElapsedInMinutes, sessionDurationInMinutes) && !overwriteAsCompleted) {
                UserEvents.sessionCancelled(session, { sessionCompletness });
            } else {
                UserEvents.sessionCompleted(session);
            }
        },
        [eventTrackingDone, session, sessionDurationInMinutes, timeElapsedInMinutes],
    );

    const sessionCompletness =
        sessionDuration && timeElapsed ? Math.round((100 * timeElapsed) / sessionDuration) : undefined;

    const stopSession = useCallback(() => {
        if (!!session) {
            if (session.renderType !== SessionRenderType.PREDICTIVE_COMPOSED) {
                /* this session score truncation should happen in the BE, 
                i.e. create request handler in request.ts that updates the session score when it receives a stopSession event, 
                which is emitted in deleteSession.ts */
                onSkipWave(session.score.wavepaths.length - 1); // this is ugly AF. This is essentially saying: skip to postlude
            }
            if (!session || !authCtx.firebaseUser) return;
            trackEndedSession({ sessionCompletness });
            if (session.renderType !== SessionRenderType.PREDICTIVE_COMPOSED) {
                sessionApi.deleteSession({ sessionId: session.id, fbUser: authCtx.firebaseUser });
            } else {
                connection?.sendRequest({
                    type: RequestType.StopBroadcast,
                });
            }
        }
    }, [connection, session, authCtx.firebaseUser, trackEndedSession, sessionCompletness]);

    const endSession = useCallback(
        (doConfirm: boolean) => {
            if (!doConfirm) {
                stopSession();
            } else {
                setShowConfirmEndSession(true);
            }
        },
        [connection, session, stopSession],
    );
    const endSessionWithConfirm = useCallback(() => {
        const needToConfirm = !sessionInPostlude && !sessionEnded;
        endSession(needToConfirm);
    }, [endSession, sessionInPostlude, sessionEnded]);

    const endSessionFromConfirm = () => {
        setShowConfirmEndSession(false);
        stopSession();
    };

    // useEffect(() => {
    //     if (!authCtx.firebaseUser && remoteSessionLogs) {
    //         stopSession();
    //     }
    // }, [stopSession, remoteSessionLogs, authCtx]);

    const sessionScore = sessionScoreState === 'loading' ? null : sessionScoreState.sessionScore;

    // TOOD: delete when we've refactored stopSession();
    const onSkipWave = useCallback(
        (waveIndex: number): void => {
            connection?.sendRequest({
                type: RequestType.SkipWave,
                index: waveIndex,
            });
            UserEvents.waveSkipped({ waveIndex });
        },
        [connection],
    );

    const [broadcastState, setBroadcastState] = useState<BroadcastPersistentState | undefined>(session?.broadcastState);
    useEffect(() => {
        if (session) setBroadcastState(session.broadcastState);
    }, [session?.broadcastState]);
    useEffect(() => {
        if (!connection) return;
        connection.on('broadcastStateUpdate', (data: BroadcastPersistentState) => {
            setBroadcastState(data);
            console.debug('New broadcastState', data);
        });
    }, [connection]);

    return {
        classes,
        showConfirmEndSession,
        endSessionFromConfirm,
        remoteSessionLogs,
        setShowConfirmEndSession,
        sessionScoreState,
        session,
        endSessionWithConfirm,
        sessionScore,
        startSession,
        stopSession,
        snackbarContent,
        setSnackbarContent,
        queuedFunction,
        onCancelQueuedFunction,
        onSkipQueue,
        onSkipQueueAndDontAskMore,
        queueFunction,
        closeSnackbar,
        warningContent,
        setWarningContent,
        trackEndedSession,
        endSession,
        connection,
        tick,
        broadcastState,
        sessionError,
        sessionInitialised,
        sessionStarted,
        sessionEnded,
    };
};

export const RemoteSessionControlsContext = createContext<ReturnType<typeof useRemoteSessionControls> | undefined>(
    undefined,
);

export const Guide: FC<{
    remoteSession: ReturnType<typeof useRemoteSessionControls>;
}> = ({ remoteSession }) => {
    const session = remoteSession.session!;
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);
    setScrollbarTheme(true);

    useMonitoringForSessionInit(session, remoteSession.sessionInitialised);

    useEffect(() => {
        if (!remoteSession.connection) return;

        remoteSession.connection.on('controlRequestResult', (data: SessionRequestProcessingResult) => {
            if (data.status === 'Ok') {
                //setSnackbarContent('Your change is now being applied...');
            } else if (data.status === 'FailedToFullfill') {
                setSnackbarContent('Your request was not fullfilled because : ' + data.reason);
                if (data.debugInfo) {
                    console.debug(data);
                }
            }
        });
    }, [remoteSession.connection]);

    // const unblock = () => {
    //     (async () => {
    //         await audioPlayer.actions.unblock();
    //     })();
    // };
    const history = useHistory();

    const authCtx = useContext(AuthContext);

    const { isEnabled } = useAuthContext();

    useEffect(() => {
        if ((session && session.endedAt) || remoteSession.sessionError) {
            window.location.href = '/session/details/' + session?.id;
        }
    }, [history, session, remoteSession.sessionError]);

    const [endOfSessionFeedbackAcknowledged, setEndOfSessionFeedbackAcknowledged] = useState(false);

    useEffect(() => {
        if (session && session.id && remoteSession.sessionEnded) {
            remoteSession.connection?.close();
            if (endOfSessionFeedbackAcknowledged) {
                window.location.href = '/session/details/' + session?.id;
            }
        }
    }, [remoteSession.sessionEnded, endOfSessionFeedbackAcknowledged, remoteSession.connection, history, session]);

    return (
        <PlayerChoiceContextWrapper sessionRenderType={session.renderType}>
            <GenericErrorBoundary>
                <div className={cn(remoteSession.classes.guide, 'tour-guideBody')}>
                    {/* <Dialog
                            open={audioPlayer.audioStatus === 'blocked'}
                            onConfirm={unblock}
                            message="In order to play sound through this tab, you first need to enable audio"
                            confirmText="Enable Audio"
                            title="Enable Audio"
                            disableBackdropClick={true}
                        /> */}
                    <Dialog
                        fullWidth={true}
                        open={remoteSession.showConfirmEndSession}
                        message={'Are you sure you want to end this session?'}
                        onClose={() => remoteSession.setShowConfirmEndSession(false)}
                        onConfirm={remoteSession.endSessionFromConfirm}
                        confirmText={'End Session'}
                    />
                    {remoteSession.remoteSessionLogs &&
                    remoteSession.sessionScoreState !== 'loading' &&
                    remoteSession.connection &&
                    remoteSession.session ? (
                        <>
                            {isEnabled(Features.PLAY_DEMO) && <UpgradeBanner />}
                            <GuideHeader
                                session={remoteSession.session}
                                onEndSession={remoteSession.endSessionWithConfirm}
                            />
                            <main className={remoteSession.classes.main}>
                                {remoteSession.sessionScore && (
                                    <AutoGuide
                                        session={remoteSession.session}
                                        log={remoteSession.remoteSessionLogs.log}
                                        variables={remoteSession.session.variableInputs}
                                        sessionScoreState={remoteSession.sessionScoreState}
                                        score={remoteSession.sessionScore}
                                        connection={remoteSession.connection}
                                        onStartSession={remoteSession.startSession}
                                        onEndSession={remoteSession.stopSession}
                                        snackbarContent={remoteSession.snackbarContent}
                                        setSnackbarContent={remoteSession.setSnackbarContent}
                                        queueFunction={remoteSession.queueFunction}
                                    />
                                )}
                            </main>
                            {remoteSession.queuedFunction && !remoteSession.queuedFunction.showQueuingUIInline && (
                                <QueuedItem
                                    queuedFunction={remoteSession.queuedFunction}
                                    onCancel={remoteSession.onCancelQueuedFunction}
                                    onSkipQueue={remoteSession.onSkipQueue}
                                    onSkipQueueAndDontAskMore={remoteSession.onSkipQueueAndDontAskMore}
                                />
                            )}
                            {/* TODO: Move snackbar to upmost parent component and create context and hook for it,
                        so it can be called from anywhere in the app without prop drilling */}
                            <Snackbar
                                type="warning"
                                isLongButton={false}
                                message={remoteSession.snackbarContent}
                                confirmText={'OK'}
                                open={remoteSession.snackbarContent !== null}
                                closeSnackbar={remoteSession.closeSnackbar}
                                className={remoteSession.classes.snackbar}
                            />
                            <Warning
                                icon={<AnimatedLoaderIcon />}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                text={remoteSession.warningContent}
                                open={!!remoteSession.warningContent}
                                onDismiss={() => remoteSession.setWarningContent(undefined)}
                            />
                        </>
                    ) : (
                        <LoadingOrb>
                            <p>Your Session is being prepared, please wait.</p>
                        </LoadingOrb>
                    )}

                    {remoteSession.connection && remoteSession.sessionScoreState !== 'loading' && (
                        <>
                            {authCtx.firebaseUser && (
                                <GuideEndOfSessionFeedbackContainer
                                    currentUser={authCtx.firebaseUser}
                                    trackEndedSession={remoteSession.trackEndedSession}
                                    connection={remoteSession.connection}
                                    onAcknowledged={() => setEndOfSessionFeedbackAcknowledged(true)}
                                    onEndSession={() => remoteSession.endSession(false)}
                                />
                            )}
                        </>
                    )}
                </div>
            </GenericErrorBoundary>
        </PlayerChoiceContextWrapper>
    );
};
