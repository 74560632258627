import { useEffect, useState } from 'react';

import { Features } from '@/features';

import { useAuthContext } from '../../auth';
import configs from '../../configs';

export function useSessionLinkForTheClient({ broadcastIdentifier }: { broadcastIdentifier: string }) {
    const { userData, isEnabled } = useAuthContext();
    const [sessionLink, setSessionLink] = useState<string>('');
    const playDemoVO = isEnabled(Features.PLAY_DEMO);

    useEffect(() => {
        if (!userData) return;
        const baseURL = configs.bonny.BASE_URL;
        const freePaidPathPart = playDemoVO ? '/free' : '/paid';
        const finalUrl = `${baseURL}/listen${freePaidPathPart}/${broadcastIdentifier}`;
        setSessionLink(finalUrl);
    }, [userData, playDemoVO]);

    return sessionLink;
}

export function useSessionLinkForTheNativeApp({
    broadcastIdentifier,
    freePaidPathPart,
}: {
    broadcastIdentifier: string;
    freePaidPathPart?: string;
}) {
    const { isEnabled } = useAuthContext();
    const playDemoVO = isEnabled(Features.PLAY_DEMO);

    const freudEnv = configs.freud.ENV;
    const freePaidPathPartCurrentUser = playDemoVO ? 'free' : 'paid';
    const freePaidPathPartEffective = freePaidPathPart ?? freePaidPathPartCurrentUser;
    const finalUrl = `wavepaths2://listen/${freudEnv}/${freePaidPathPartEffective}/playerlib/${broadcastIdentifier}`;

    return finalUrl;
}

export function useSessionLinkForControls({ sessionId }: { sessionId: string }) {
    const { userData } = useAuthContext();
    const [sessionLink, setSessionLink] = useState<string>('');
    useEffect(() => {
        if (!userData) return;
        const baseURL = configs.bonny.BASE_URL;
        const finalUrl = `${baseURL}/session/${sessionId}`;
        setSessionLink(finalUrl);
    }, [userData]);

    return sessionLink;
}
